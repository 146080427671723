export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/(notebook)/about": [5,[2]],
		"/console": [12],
		"/(notebook)/cpython/[...path]": [~6,[2]],
		"/(workspace)/gist": [7],
		"/(workspace)/gist/[gist_id]": [8],
		"/(workspace)/github": [9],
		"/(workspace)/github/[owner]/[repo]": [~10],
		"/oauth": [~13],
		"/(workspace)/playground": [11],
		"/pypi": [14,[3]],
		"/pypi/[project]": [~15,[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';